import { Customer }     from "@/model/Customer";
import { Order }        from "@/model/Order";
import { Zone }         from "@/model/Zone";
import { Options, Vue } from "vue-class-component";

import {
  AddressAutocomplete, CustomerAutocomplete, PageHeader, PickerAutocomplete, ProgressDialog, TimeslotSelect,
  VehicleAutocomplete, ZoneAutocomplete,
} from "@/components"

import { EntityPicker, EntityPickerType } from "@/model/Entity";
import { Vehicle }                        from "@/model/Vehicle";
import { ordersService }                  from "@services/orders.service";
import { zonesService }                   from "@services/zones.service";
import { customersService }               from "@services/customers.service";
import { fileDownload, formattedDate }    from "@sharedUtils/utils";
import moment                             from "moment";

@Options( {
  components: {
    PageHeader,
    AddressAutocomplete,
    CustomerAutocomplete,
    PickerAutocomplete,
    ProgressDialog,
    TimeslotSelect,
    VehicleAutocomplete,
    ZoneAutocomplete
  }
})
export default class OrdersImportPage extends Vue {
  date: Date = new Date();

  pickup_datetime: Date = new Date();

  shift: number = null;

  zone: Zone = null;

  customer: Customer = null;

  picker: EntityPicker = null;

  vehicle: Vehicle = null;

  ordersCreated  : Order[] = null;
  isCreatedPanelCollapsed: boolean = true;

  ordersDefective: Order[] = null;
  isDefectivePanelCollapsed: boolean = true;

  /**
   * Display progress bar during upload
   */
  displayProgress: boolean = false;

  file: File = null;

  get pickerType() {
    return EntityPickerType.PICKUP;
  }

  get isJson() {
    return this.file?.type === "application/json";
  }

  onTimeslotChange({value: shift_id}) {
    const selectedShift = this.$store.getters.shiftById( shift_id )
    if (!selectedShift) return;

    const [ h, m, s ]    = selectedShift.start_time.split( ':' );
    this.pickup_datetime = new Date( this.date );
    this.pickup_datetime.setHours( +h, +m, +s );
  }

  onFileChange(event: Event) {
    this.file = (event.target as HTMLInputElement).files[0];
    this.$emit('select', this.file);
  }

  removeFile() {
    this.file = null;
  }

  onSelectAddress(data, address) {
    data.dropoff_lat = address.geometry.location.lat();
    data.dropoff_lon = address.geometry.location.lng();
  }

  onCustomerSelect() {
    this.picker  = null;
    this.vehicle = null;

    customersService.getById( this.customer.id ).then( response => {
      this.customer = response;
    } );
  }

  onZoneSelect(zone: Zone) {
    zonesService.getById(zone.id).then(response => {
      this.zone = response;
    });

    this.customer = null;
    this.picker   = null;
    this.vehicle  = null;
  }

  get excludedVehiclesIds(){
    return this.customer?.vehicle_data
      ?.filter(v => !v.is_enabled)
      ?.map(v => v.vehicle_id);
  }

  private _updatePanelsStatus() {
    this.isCreatedPanelCollapsed   = !this.ordersCreated?.length;
    this.isDefectivePanelCollapsed = !this.ordersDefective?.length;
  }

  restore() {
    try {
      this.displayProgress = true;

      this.file.text().then(text => {
        const { mainData, defectiveOrders } = JSON.parse(text);

        this.date     = new Date(mainData.date);
        this.shift    = mainData.shift;
        this.zone     = mainData.zone;
        this.customer = mainData.customer;
        this.picker   = mainData.picker;
        this.vehicle  = mainData.vehicle;

        this.pickup_datetime = new Date(mainData.pickup_datetime);
        this.ordersDefective = defectiveOrders;
      });

    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.file            = null;
        this.displayProgress = false;
        this._updatePanelsStatus();
      }, 200);
    }

  }

  uploadFile() {
    const allFieldRequiredError = [
      this.date,
      this.shift,
      this.zone,
      this.customer,
      this.picker,
      this.vehicle
    ].some(x => !x );

    if (allFieldRequiredError) {
      this.$errorMessage("Riempire tutti i campi");
      return;
    }

    this.displayProgress = true;

    this.$waitFor(async () => {

      try {
        const response = await ordersService.importOrders(this.file, {
          customer_id      : this.customer.id,
          zone_id          : this.zone.id,
          pickup_picker_id : this.picker.id,
          order_date       : formattedDate(this.date),
          vehicle_id       : this.vehicle.id,
          shift_id         : this.shift,
          pickup_datetime  : this.pickup_datetime.toISOString(),
        });

        this.ordersCreated   = response.orders_created;
        this.ordersDefective = response.orders_defective;

        this.$successMessage(
          "Vedi le tabelle per maggiori dettagli",
          "Caricamento riuscito"
        );
      } finally {
        this.file = null;

        this._updatePanelsStatus();

        this.displayProgress = false;
      }

    }, "Caricamento ordini non riuscito");
  }

  retry() {
    this.displayProgress = true;

    this.$waitFor(async () => {
      try {
        const response = await ordersService.storeBulk(this.ordersDefective);

        this.ordersCreated   = [
          ...(this.ordersCreated || []),
          ...response.orders_created
        ];

        this.ordersDefective = response.orders_defective;

        this.$successMessage(
          "Vedi le tabelle per maggiori dettagli",
        );
      } finally {

        this._updatePanelsStatus();

        this.displayProgress = false;
      }

    }, "Caricamento ordini non riuscito");
  }

  downloadDefected() {
    const dateString = moment(this.date).format("yyyy-MM-DD");
    const fileName   = `defective_orders_${dateString}.json`;

    const defectiveOrders = this.ordersDefective;

    const mainData = {
      date            : this.date,
      shift           : this.shift,
      zone            : this.zone,
      customer        : this.customer,
      picker          : this.picker,
      vehicle         : this.vehicle,
      pickup_datetime : this.pickup_datetime
    }

    const data = { mainData, defectiveOrders };

    fileDownload(fileName, JSON.stringify(data), "application/json");
  }

}
